<template>
    <div>
            <section class="vechile-list page-content">
                <div class="container">

                    <div class="vechile-list-container main-container">
                        <div class="vechile-list_row">
                            <div class="row gx-5 justify-content-between">
                                    <!-- Components -->
                                    <Payment />                           
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
</template>

<script>
import { defineComponent, computed, ref } from 'vue';
import Payment from '@/components/Payment.vue';
import { useStore } from '@/store';
import SecureCheckoutPage from '@/core/services/car/BuyNow';
import Coupon from '@/core/services/car/Coupons';
import CarDriver from '@/core/services/car/Drivers';
import Stepper from '@/core/services/etc/Steppers';
import Checkout from '@/core/services/car/Checkouts';

export default defineComponent({

    components: {
        Payment,
    },
    
    created() {
        const routeValue = {
            vehicle: 'done',
            driver: 'done',
            compare: 'done',
            cover: 'active'
        }
        Stepper.setVehicleDetails(routeValue);
        SecureCheckoutPage.setCheckout(JSON.parse(window.localStorage.getItem('qoutes')));
        SecureCheckoutPage.setBuyNow(JSON.parse(window.localStorage.getItem('buyNow')));
        Coupon.setCoupon(JSON.parse(window.localStorage.getItem('coupon')));
        CarDriver.setInitialDriverDetails();
        Checkout.fetchPayfort({
            id: window.localStorage.getItem('form')
        })
    },

    setup() {
        const store = useStore();

        const buyNow = computed(() => {
            return store.state.buyNow.buyNow
        })

        return {
            buyNow,
        }
    },
})
</script>
